<template>
  <div class="componentSty">
    <div class="componentSty_content">
      <div class="componentSty_content_title">
        <el-form inline size="small">
          <el-form-item label="联系人名称"
            ><el-input
              placeholder="请输入关键词"
              v-model.trim="conditions.name"
              clearable
            >
            </el-input
          ></el-form-item>
          <el-form-item label="联系人电话"
            ><el-input
              placeholder="请输入关键词"
              v-model.trim="conditions.phone"
              clearable
            >
            </el-input
          ></el-form-item>
          <el-form-item>
            <el-button type="primary" class="buttons" @click="handleSearch"
              >查询</el-button
            >
          </el-form-item>
          <el-form-item style="float: right">
            <el-button type="primary" class="buttons" @click="handleAdd"
              >新增联系人</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div class="componentSty_content_table">
        <el-table border :data="tableList" style="width: 100%">
          <el-table-column
            show-overflow-tooltip
            label="序号"
            type="index"
            width="50px"
            :index="indexMethod"
          ></el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="联系人名称"
            prop="name"
          ></el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="联系人电话"
            prop="phone"
          ></el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="创建时间"
            prop="gmtCreate"
          ></el-table-column>
          <el-table-column label="操作">
            <template v-slot="scope">
              <el-button
                type="text"
                size="mini"
                @click.stop="handleEdit(scope.row)"
              >
                修改
              </el-button>
              <el-button
                type="text"
                size="mini"
                @click="handleDelete(scope.row.id)"
                style="color: rgb(254, 90, 36)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <Pagination
          :total="total"
          :page="conditions.page"
          :pageSize="conditions.limit"
          @paging="getPaging"
        />
        <AddContacts
          v-if="showAdd"
          ref="addContactsRef"
          @confirm="handleConfirm"
          @cancel="handleCancel"
        ></AddContacts>
      </div>
    </div>
  </div>
</template>
<script>
import AddContacts from "./addContacts.vue";
import Pagination from "@/components/pagination.vue";
export default {
  data() {
    return {
      enumStatus: {
        0: { label: "待审核", type: "" },
        1: { label: "正常", type: "success" },
        2: { label: "审核未通过", type: "danger" },
        3: { label: "停用", type: "info" },
      },
      conditions: {
        page: 1,
        limit: 10,
        name: "",
        phone: "",
      },
      total: 0,
      tableList: [],
      showAdd: false,
    };
  },
  components: {
    AddContacts,
    Pagination,
  },
  created() {
    this.getList();
  },
  methods: {
    indexMethod(index) {
      return (this.conditions.page - 1) * this.conditions.limit + index + 1;
    },
    // ...mapMutations(["createConditions"]),
    // 获取列表数据
    getPaging(val) {
      this.conditions.page = val.page;
      this.conditions.limit = val.pageSize;
      this.getList();
    },
    async getList() {
      const { data } = await this.$http(
        "api1",
        "/api/biz/ContactsInfo/list",
        "get",
        this.conditions
      );
      console.log(data);
      if (data.code != 0) return this.$message.error(data.msg);
      this.tableList = data.data.list;
      this.total = data.data.totalCount;
    },
    handleSearch() {
      this.conditions.page = 1;
      this.getList();
    },
    handleAdd() {
      this.showAdd = true;
    },

    handleConfirm() {
      this.showAdd = false;
      this.getList();
    },
    handleCancel() {
      this.showAdd = false;
    },
    handleEdit(row) {
      this.showAdd = true;
      this.$nextTick(() => {
        this.$refs.addContactsRef.id = row.id;
        this.$refs.addContactsRef.isEdit = true;
        for (let key in this.$refs.addContactsRef.form) {
          this.$refs.addContactsRef.form[key] = row[key];
        }
      });
    },
    handleDelete(id) {
      this.$messageBox
        .confirm("确认删除该联系人, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(async () => {
          const { data } = await this.$http(
            "api1",
            "/api/biz/ContactsInfo/delete",
            "post",
            {
              ids: [id],
            }
          );
          if (data.code != 0) {
            return this.$message.error(data.msg);
          } else {
            this.$message.success("操作成功");
            this.getList();
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped></style>
